<template>

    <div class="login-bg flex-column">
      <div class=" safe-area flex-column justify-center align-items-center flex-auto">
        <div class="column margin-top-30" style="width:800px">
          <div class="font-size-20 border-bottom padding-bottom-10">用户注册</div>
          <el-tabs v-model="activeType" @tab-click="initForm">
            <el-tab-pane label="普通客户" name="1"></el-tab-pane>
            <el-tab-pane label="内部客户" name="5"></el-tab-pane>
          </el-tabs>
          <div class="margin-top-20">
              <el-form :model="form" ref="form" label-width="auto" :key="activeType">
                <template v-if="activeType==1">
                  <el-form-item prop="register_user_name" label="账号"
                  :rules="[{ required: true, message: '此项不能为空'},{validator:validatePhone,trigger: 'blur'}]"
                  >
                    <el-input v-model.trim="form.register_user_name" autocomplete="off" placeholder="请输入可用手机号"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="font-size-12 color-sub" style="line-height: 1.5em; word-break: normal;">密码必须是6-20位组合密码，至少包含字母、数字、字符中的两种</div>
                  </el-form-item>
                  <el-form-item prop="pwd" label="密码"
                    :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input type="password" v-model.trim="form.pwd" autocomplete="off" placeholder="请输入密码"></el-input>
                  </el-form-item>
                  <el-form-item prop="confirm_pwd" label="确认密码"
                    :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input type="password" v-model.trim="form.confirm_pwd" autocomplete="off" placeholder="请再次输入密码"></el-input>
                  </el-form-item>
                  <el-form-item label="是否药剂师" prop="is_pharmacist">
                    <el-switch v-model="form.is_pharmacist"></el-switch>
                  </el-form-item>
                  <el-form-item prop="validate_code" label="图形验证码" :rules="[{ required: true, message: '此项不能为空'}]">
                    <div class="flex-row">
                      <el-input type="text" v-model.trim="form.validate_code"  autocomplete="off" placeholder="图形验证码"></el-input>
                      <el-image @click="codeImgRefresh" class="link" style="width: 130px; height: 40px; margin-left:10px;" :src="codeImg"  fit="fill">
                        <div slot="error" @click="codeImgRefresh" class="text-align-center">图片加载失败</div>
                      </el-image>
                    </div>
                  </el-form-item>
                </template>
                <template v-if="activeType==5">
                  <el-form-item prop="register_user_name" label="账号"
                  :rules="[{ required: true, message: '此项不能为空'},{validator:validateLetterOrNum,trigger: 'blur'}]"
                  >
                    <el-input v-model.trim="form.register_user_name" autocomplete="off" placeholder="请输入姓名全拼或全拼+数字"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="font-size-12 color-sub" style="line-height: 1.5em; word-break: normal;">密码必须是6-20位组合密码，至少包含字母、数字、字符中的两种</div>
                  </el-form-item>
                  <el-form-item prop="pwd" label="密码"
                    :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input type="password" v-model.trim="form.pwd" autocomplete="off" placeholder="请输入密码"></el-input>
                  </el-form-item>
                  <el-form-item prop="confirm_pwd" label="确认密码"
                    :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input type="password" v-model.trim="form.confirm_pwd" autocomplete="off" placeholder="请再次输入密码"></el-input>
                  </el-form-item>
                  <el-form-item prop="user_rela_name" label="姓名"
                  :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input v-model.trim="form.user_rela_name" autocomplete="off" placeholder="请输入真实姓名"></el-input>
                  </el-form-item>
                  <el-form-item prop="user_contact_tel" label="联系电话"
                  :rules="[{ required: true, message: '此项不能为空'},{validator:validatePhone,trigger: 'blur'}]"
                  >
                    <el-input v-model.trim="form.user_contact_tel" autocomplete="off" placeholder="请输入可用手机号"></el-input>
                  </el-form-item>
                  <el-form-item prop="private_code" label="专用代码"
                  :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input v-model.trim="form.private_code" autocomplete="off" placeholder="请输入内部用户专用代码"></el-input>
                  </el-form-item>
                  <el-form-item prop="sh_neiqin" label="专属内勤人员"
                  :rules="[{ required: true, message: '此项不能为空'}]"
                  >
                    <el-input v-model.trim="form.sh_neiqin" autocomplete="off" placeholder="请输入专属内勤人员姓名"></el-input>
                  </el-form-item>
                  <el-form-item prop="validate_code" label="图形验证码" :rules="[{ required: true, message: '此项不能为空'}]">
                    <div class="flex-row">
                      <el-input type="text" v-model.trim="form.validate_code"  autocomplete="off" placeholder="图形验证码"></el-input>
                      <el-image @click="codeImgRefresh" class="link" style="width: 130px; height: 40px; margin-left:10px;" :src="codeImg"  fit="fill">
                        <div slot="error" @click="codeImgRefresh" class="text-align-center">图片加载失败</div>
                      </el-image>
                    </div>
                  </el-form-item>
                </template>
              </el-form>
              <div class="flex-row margin-tb-20 ">
                <el-checkbox v-model="agreement">我已阅读并同意</el-checkbox>
                <div class="color-yellow link-text">《用户服务协议及隐私政策》</div>
              </div>

             <div class="flex-row justify-center"> <el-button type="primary" round :loading="loading" @click="submitForm('form')">注 册</el-button></div>

              
          </div>
          <div class="flex-row justify-between margin-20 ">
            <div class="link-text"></div>
            <div class="link-text" @click="$root.jump({path:'/login'})">已有账号，去登录</div>
            
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import Fingerprint2 from 'fingerprintjs2'
export default {
  name: 'Register',
  components:{
    
  },
  data(){
    return {
      loading:false,
      activeType:"1",
      form: {
        register_user_name:"",
        pwd: '',
        confirm_pwd:'',
        is_pharmacist:false,
        validate_code:'',
        user_rela_name:'',
        user_contact_tel:'',
        private_code:'',
        sh_neiqin:'',
      },
      codeImg:'',
      deviceToken:'',
      agreement:false,//是否勾选同意协议
      validatePhone:(rule, value, callback)=>{
        if(!this.verPhone(value)){callback(new Error('请输入正确手机号'));}else{callback()}
      },
      validateLetterOrNum:(rule, value, callback)=>{
        let reg=/^(?!d)[a-zA-Z0-9]+$/
        if(!reg.test(value)){callback(new Error('请输入英文字母或字母数字组合'));}else{callback()}
      },
    }
  },
  computed:{
    
  },
  created(){
    Fingerprint2.get((components)=>{
      const values = components.map(function(component,index) {
        if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
          return component.value.replace(/\bNetType\/\w+\b/, '')
        }
        return component.value
      })
      
      // 生成最终id murmur   
      this.deviceToken = Fingerprint2.x64hash128(values.join(''), 31)
    })
    this.codeImgRefresh()

  },
  mounted(){

  },
  methods:{
    codeImgRefresh(){
      this.codeImg=`${this.$server}/user/generate_validate_code?device_token=${this.deviceToken}&stamp=${Math.random()}`
    },
    //手机号验证格式
    verPhone(p, required = true) {
      if (!p) {
        return required ? false: true;
      } else {
        // 必须是1开头，第二位数字可以是0-9任意一个，总长为11
        let reg = /^1([0-9])\d{9}$/;
        // 必须是1开头，第二位数字可以是3|5|6|7|8|9任意一个，总长为11
        // let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(p)) {
          return true;
        } else {
          return false;
        }
      }
    },
    initForm(){
      this.form={
        register_user_name:"",
        pwd: '',
        confirm_pwd:'',
        is_pharmacist:false,
        validate_code:'',
        user_rela_name:'',
        user_contact_tel:'',
        private_code:'',
        sh_neiqin:'',
      }
    },
    register(){
      this.loading=true
      let params= {
        ...this.$store.state.basicParams,
        register_user_name:this.form.register_user_name,
        pwd: this.form.pwd,
        confirm_pwd:this.form.confirm_pwd,
        device_token:this.deviceToken,
        validate_code:this.form.validate_code,
        is_pharmacist:this.form.is_pharmacist?1:0,
        role_id:this.activeType,
        user_rela_name:this.form.user_rela_name,
        user_contact_tel:this.form.user_contact_tel,
        private_code:this.form.private_code,
        sh_neiqin:this.form.sh_neiqin,
      }
      this.$instance.post('/user/register',this.$qs.stringify(params))
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.$confirm('恭喜您，注册成功。请前往登录页面登录。', '成功提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '登录',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.$root.jump({
              path:'/login'
            })
          })
          .catch(() => {
            this.$router.replace({
              path: '/',
            })
          });
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    },
    submitForm(formName) {
      if(!this.agreement){
        this.$message({message: '请勾选阅读同意《用户服务协议及隐私政策》',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      if(this.form.password!=this.form.confirmPwd){
        this.$message({message: '两次密码输入不一致',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      let input=document.getElementsByTagName('input')
      for(let i=0;i<input.length;i++){
        input[i].blur()
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.register()
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  // .login-bg{
  //   min-height: 600px;
  //   min-width: 1200px;
  //   background: url('../assets/images/login-bg.jpg') center center no-repeat;
  //   background-size: cover;
  // }
</style>
